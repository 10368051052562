@font-face {
  font-family: "DrukPort";
  src: url(../Assets/Fonts/DrukCond-Super-Web.woff2);
  font-stretch: condensed;
}
@font-face {
  font-family: "Recife";
  src: url(../Assets/Fonts/RecifeDisplay-Book.woff);
}
@font-face {
  font-family: "HostLight";
  src: url(../Assets/Fonts/HostGrotesk-Light.woff);
}@font-face {
  font-family: "HostRegular";
  src: url(../Assets/Fonts/HostGrotesk-Regular.woff);
}@font-face {
  font-family: "HostMedium";
  src: url(../Assets/Fonts/HostGrotesk-Medium.woff);
}@font-face {
  font-family: "HostBold";
  src: url(../Assets/Fonts/HostGrotesk-Bold.woff);
}

@font-face {
  font-family: "RoobertLight";
  src: url(../Assets/Fonts/Roobert-Light.woff2);
}
@font-face {
  font-family: "EditorialLight";
  src: url(../Assets/Fonts/PPEditorialNew-Ultralight-BF644b21500d0c0.woff2);
}
@font-face {
  font-family: "EditorialItalic";
  src: url(../Assets/Fonts/PPEditorialNew-UltralightItalic-BF644b214ff1e9b.woff2);
}






.App {
  width: 100%;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

html,
body {
  margin: 0px;
  background-color: #04031A;
  height: 100%;
}
.hero {
  position: relative;
  left: 0;

  top: 0px;
  max-width: 100%;
  overflow: hidden;
  height: 90vh;
}

.latest-cs-text{
  font-family: "HostLight",sans-serif ;
  font-size: calc(.5vw + .5vh + .5vmin) ;
 line-height: calc(.7vw + .6vh + .8vmin) ;
width: fit-content;
color: #ffffff;
margin-block-end: .8em;
font-weight: 100;
margin-block-start: 0em;
}

.latest-cs-cta{
text-decoration: none;
color: #6899F9;
}

.latest-cs-image{

  width: 40px;
  height: 40px;
  border-radius: 10px;
  
}

.latest-cs-image img{

  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  
}

.main-latest-cs{

 /* Inner content background */
  padding: 1px;
  height: fit-content;
  width: fit-content;

  position: relative; /* Important for the pseudo-element */
  z-index: 1; /* Keep content above the pseudo-element */
  
}

.latest-cs {
  display: flex;
  gap: 20px;
  background-color: #04031A; /* Inner content background */
  padding: 16px;
  height: fit-content;
  width: fit-content;
  border-radius: 10px;
  position: relative; /* Important for the pseudo-element */
  z-index: 1; /* Keep content above the pseudo-element */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Animated border with moving gradient */
.main-latest-cs:before {
  content: "";
  position: absolute;
  top: -.7px;
  left: -.7px;
  right: -.7px;
  bottom: -.7px;
  border-radius: 10px; /* Ensure it matches the box's border-radius */
  background: linear-gradient(270deg, #ffffff0f, #ffffff4e,  #ffffff0f,  #ffffff4e);
  z-index: -1; /* Keep it behind the content */
  background-size: 200% 200%;
  animation: moving-border 8s linear infinite;
  padding: 4px; /* Create space for the animated border */
}


@keyframes moving-border {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.header-para h3{
  color: white;
  font-family: "HostLight";
  font-size: calc(.5vw + .5vh + .5vmin) ;
  font-weight: 100;
}
.latest-cs span{
 width: 70%;

 font-size: calc(.5vw + .5vh + .5vmin) ;
 line-height: calc(.7vw + .6vh + .8vmin) ;

 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-content: center;

}


.header-div{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.Text2{
display: flex;
flex-direction: column;
  text-align: left;
align-items: flex-start;
gap: 40px;
}

.header-para{
  padding-left:calc(4vw + 4vh + 3vmin);
  display:flex;
  padding-right: calc(4vw + 4vh + 3vmin);
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  
}
.header-para p {
  font-family: "HostLight";
  font-weight: normal;
  color: rgb(255, 255, 255);
  font-size: calc(1vw + 0.5vh + 0.5vmin);
  text-align: left;
  margin-block-start: 0em;
  width: 60%;
  margin-block-end: 0em;
  line-height: calc(1vw + 1vh + 1vmin);
}
.header-para span {
  font-family: "EditorialItalic";
  color: #6899F9;
  font-weight: 100;

}


.header-para h1{
  font-family: "EditorialLight", sans-serif;
  color: #0057FF;
  font-size: calc(4vw + 4vh + 3vmin);
  line-height: calc(4vw + 4vh + 4vmin);
  margin-block-end: 0em;
  margin-block-start: 0em;
  font-weight: 100;
  width: 85%;
}

.text-header {
padding-left: 0px;
}

.HI1{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  z-index: -899999;
}
.header-image{
 position: absolute;
 height: auto;
 top: -20%;
 right: 5%;

}





.circle-3{
  height: 400px;
  width: 400px;
  position: absolute;
  background-color: #9640f2;
  filter: blur(100px);
  opacity: 0.26;
  left: 474px;
  top: 130vh;
  z-index: -84848;
}



.circle4 {
  height: 400px;
  width: 400px;
  background-color: #7140f2;
  opacity: 0.26;
  left: -100px;
  top: 100px;
  border-radius: 50%;
  position: absolute;
  filter: blur(100px);
  z-index: -99999999;
}

.circle5 {
  height: 400px;
  width: 400px;
  position: absolute;
  right: -100px;
  background-color: #9640f2;
  filter: blur(100px);
  opacity: 0.26;
  top: 200px;
}


.marquee {
  white-space: nowrap;
  /* overflow: hidden; */
  display: inline-block;
  animation: marquee 40s linear infinite;
}
.marquee p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0.0001em;
}

.marquee p {
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.scroll-img {
  top: 80vh;
  position: absolute;
  width: 150px;
  float: right;
  right: 50px;
}
.hover-header {
  position: relative;
}



@media screen and (max-device-width: 1200px) {
  .memoji {
    display: none;
  }
  .header-text {
    font-size: calc(2.6em + 1vw);
    top: 10vh;
    left: -20px;
  }
  .hi-img {
    display: none;
  }
}

@media screen and (min-device-width: 312px) and (max-device-width: 600px) {
  .hero{
    margin-top: 40vh;
 overflow:visible;
 height: auto;
  }
  .App {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
  }
  .header {
    background-color: hotpink;
  }
  .header-image{
    position: absolute;
    height: 80vh;
   left: 0;
   }

.circle-3{
  display: none;
}

  .text-header {
    bottom: 10px;
    left: 20px;
    font-size: calc(4vw + 2vh + 6vmin);

  }
.header-para{
  padding-left: 20px;
  padding-right: 20px;
justify-content: flex-start;
gap: 20px;

}
  .text-header h1{
    line-height: calc(3vw + 3vh + 3vmin);
    font-size: calc(3vw + 3vh + 3vmin);

  }
  .AWF {
    padding-left: 0px !important;
}

.Text2{
  width: 100%;
  bottom: 16vh;
  right: 0vh;
  gap: 20px;
  flex-direction: column-reverse;
  height: auto;
}
.div-ex{
width: 100vw;
padding-left: 20px;

}

  .scroll-img {
    top: 650px;
    position: absolute;
    width: 100px;

    right: 20px;
  }
}
@media screen and (max-device-width: 800px) {
  .header-para p {
    width: 90%;
    line-height: calc(1vw + 2vh + 2vmin);
    font-size: calc(1.5vw + 1vh + 1.55vmin);
  }

  .circle1 {
    height: 400px;
    width: 400px;
    background-color: #0057FF;
    opacity: 0.26;
    left: -100px;
    top: 06px;
    border-radius: 50%;
    position: absolute;
    filter: blur(100px);
    z-index: -99999999;
  }
  .circle2 {
    height: 250px;
    width: 250px;
    position: absolute;
    right: 100px;

    background-color: #0057FF;
    filter: blur(100px);
    opacity: 0.26;
    top: 50vh;
  }
  .header-div {
  flex-direction: column;
  }
  .header-para h3 {
   display: none;
}
  .main-latest-cs{
display: none;
  }
  /* Animated border with moving gradient */
 

}
