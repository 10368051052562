/* Container for the case study */
.case-study-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
}

/* Left-Side Menu */
.menu-test {
  font-family: "HostLight", sans-serif;
  width: 25%;
  position: sticky;
  top: 100px; /* Adjust based on the height of your navigation bar */
  height: fit-content; /* Account for the nav bar's height */
  /* overflow-y: auto; */
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 12px;
  border-right: 1px solid #ddd;
  /* Ensures it stays above other elements */
  transition: top 0.3s ease-in-out;
  z-index: 100;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ); /* Your gradient */
  border-right: 2px solid transparent; /* Transparent border to simulate the gradient */
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-color: rgba(0, 0, 0, 0.149);
  border: 1px solid rgba(255, 255, 255, 0.077);
}


/* Menu List */
.menu-test ul {
  list-style-type: none;
  padding: 0;
}
.menu-link {
  text-decoration: none;
  padding: 10px;
  position: relative; /* Ensure the arrow is positioned relative to the link */
  color: #000;
  opacity: 0.25; /* Default opacity for inactive links */
  transition: opacity 0.3s ease, color 0.3s ease;
}

/* When the footer comes into view, allow the menu to scroll up */
.menu-test.unfixed {
  position: relative; /* Menu scrolls with the page */
  top: auto; /* Remove sticky behavior */
}

.menu-test ul li {
  margin-bottom: 10px;
}

.menu-link.active {

  opacity: 1; /* Full opacity when active */
  font-weight: bold;
  left:30px;
  color: #ffffff; /* Active color */
}

.menu-link.active::before {
  content: "→"; /* Arrow */
  color: #ffffff; /* Arrow color */
  position: absolute;
  left: -30px; /* Adjust positioning */
  top: 50%;
  transition: all 0.5s ease;
  transform: translateY(-50%); /* Vertically center the arrow */
  font-size: 1.5em; /* Increase size for better visibility */
}

.menu-test p {
  font-family: "HostBold", sans-serif;
  color: white;
  text-transform: uppercase;
  letter-spacing: 4px;
  opacity: 0.4;
  font-size: 14px;
}

/* Menu Links */
.menu-test ul li a {
  text-decoration: none;
  color: #ffffff; /* White text for better contrast on dark background */
  font-size: 1.1rem; /* Use a consistent font size */ /* Ensure full clickable area */
  padding: 10px 0; /* Add padding for easier clicking */
  opacity: 0.25; /* Default opacity for inactive links */
  transition: color 0.3s ease, opacity 0.3s ease;
}

.menu-test li {
  margin-bottom: 10px;
}

.menu-test ul li a:hover {
  color: #fff; /* Highlight color on hover */
  opacity: 1;
}

.bg-grad {
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  height: 30vh;
  width: 100vw;
}
.case-study-main {
  width: 75%;
  padding-left: 60px;
  padding-right: 60px;
}

.projectLogo {
  height: 50px;
  width: 50px;
}

.projectLogo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 60px;
}

/* Right-Side Content */
.content {
  width: 80%;
  padding: 20px;
  margin-left: 20%;
  background-color: #ffffff; /* White background for better contrast */
  color: #333; /* Dark text color for readability */
}

/* Sections in the right-side content */
.content section {
  margin-bottom: 100px;
  height: 600px; /* Height to simulate long content */
  padding: 20px;
  background-color: #f0f0f0; /* Lighter background for the content blocks */
  border: 1px solid #e0e0e0; /* Border to separate content visually */
}

.content section h2 {
  margin-bottom: 20px;
  color: #000; /* Ensure headings are clearly visible */
}

/* Filler content with background color for visual distinction */
.filler {
  height: 400px;
  background-color: #d0d0d0;
  padding: 10px;
  color: #000; /* Ensure text is dark for contrast */
}

.scrolly-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.text-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; /* Necessary to stack text on top of each other */
}

.text-item {
  opacity: 0;
  position: absolute;
  transition: opacity 0.7s ease-in-out;
}

.text-item.active {
  opacity: 1;
}

.image-section {
  position: sticky;
  top: 50%;
  transform: translateY(-50%); /* Centers the image vertically */
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* For overlapping images */
}



.scrolly-image {
  max-width: 100%;
  height: auto;
  opacity: 0;
  position: absolute;
  transition: opacity 0.7s ease-in-out;
}

.scrolly-image.active {
  opacity: 1;
}

-- .about-sec {
  position: relative;
  left: 0;
  top: 0px;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: calc(5vw + 10vh + 10vmin);
  margin-top: calc(5vw + 10vh + 10vmin);
  overflow-x: hidden;
}
.cont {
  margin: 25px;
}

.tooltipStyleChange {
  color: #006080;
}

/**/

.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted #ccc;
    color: #006080; */
}

.tooltip .tooltiptext.light {
  background-color: #ffffff;
  color: #8a4646;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.tooltip .tooltiptext.dark {
  background-color: #ffffff0a;
  color: #ffffff;
}

.tooltip .tooltiptext {
  font-family: "RoobertLight", sans-serif;
  visibility: hidden;
  position: absolute;
  width: 320px;
  text-align: left;
  padding: 10px 15px;
  border-radius: 12px;
  z-index: 1;
  line-height: 1.3em;
  opacity: 0;
  transition: opacity 0.3s;
  border: 2px solid rgba(255, 255, 255, 0.085);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-right {
  top: -5px;
  left: 125%;
}

.tooltip-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.tooltip-bottom {
  top: 135%;
  margin-left: -20px;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-left {
  top: -5px;
  bottom: auto;
  right: 128%;
}
.tooltip-left::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

.arrow-t {
  position: absolute;
  left: 48%;
  top: -10px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fff;
  z-index: 1;
}

.text-header-about {
  position: absolute;
  padding-left: calc(4vw + 4vh + 3vmin);
  bottom: 50px;
  width: 100vw;
}

.text-header-work {
  margin-top: 150px;
  position: relative;
  padding-left: 110px;
}

.ng-about {
  position: relative;
  margin-top: 110vh;
}
.ng-about2 {
  display: none;
}

.about-div p {
  color: white;
  font-size: calc(1.5vw + 1.5vh + 1.5vmin);
  font-family: "HostLight", sans-serif;
  width: 80%;
  margin-block-start: 1.5em;
  margin-block-end: 0em;
  line-height: 1.3;
}
.about-bg {
  height: 100vh;
position: absolute;
  width: 100vw;
  background-position: center;
  background-image: url("https://ik.imagekit.io/harshitsharma/about/about_bg_Kh3AoUvcW.svg?updatedAt=1725473180607");
}
.about-span {
  height: 100vh;

  width: 100vw;
}

.about-div {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  padding-left: 50px;
}

.company {
  width: auto;
  height: 80px;
}
.img1,
.img2 {
  width: auto;
  height: 90px;
}

.company-images:hover .company {
  opacity: 0.1;
  transition: 200ms ease;
}
.company-images .company:hover {
  opacity: 1;
  transition: 200ms ease;
}
.peace_of_mind {
  text-align: center;
  background: url("../Assets/peace_of_mind.svg");
  padding-bottom: calc(4vw + 4vh + 4vmin);
  background-repeat: no-repeat;
  background-position: 90px;
}

.peace_of_mind h1 {
  font-family: "EditorialItalic", sans-serif;
  font-weight: 200;
  color: white;
  text-transform: uppercase;
  font-size: calc(3.3vw + 3.2vh + 3.2vmin);
  text-align: center;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
}
.peace_of_mind p {
  font-family: "HostLight", sans-serif;
  font-weight: 100;
  color: white;
  margin: auto;
  width: 50%;
  font-size: calc(0.5vw + 0.6vh + 0.6vmin);
  line-height: calc(0.9vw + 0.9vh + 0.9vmin);
  text-align: center;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
}

.company-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 0px;
  padding-left: 10vh;
  padding-right: 10vh;
  padding-top: 50px;
}

.header-para-about h1 {
  font-family: "EditorialLight", serif;

  color: #0057ff;

  font-size: calc(4vw + 4vh + 4vmin);
  margin-block-start: -0.2em;
  font-stretch: condensed;
  margin-block-end: 0em;
  font-weight: 400;
  font-style: normal;
}
.text-header-work h1 {
  font-family: "EditorialLight", sans-serif;
  color: #0057ff;
  font-size: calc(4vw + 4vh + 8vmin);
  margin-block-start: -0.2em;
  font-stretch: condensed;
  margin-block-end: 0em;
  font-weight: 400;
  font-style: normal;
}

hr.solid {
  border-top: 1px solid rgba(253, 253, 253, 0.148);
}
hr.line {
  border-top: 1px solid rgba(132, 132, 132, 0.148);
  width: 100%;
}
.expertise h1 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  font-family: "EditorialItalic", serif;
  font-size: calc(4vw + 5vh + 4vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-weight: 100;
  text-align: center;
  margin-top: 80rem;
}
.expertise {
  position: relative;
  height: 150vh; /* Makes sure the section is scrollable */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10rem;
}

.expertise p {
  font-family: "HostLight", sans-serif;
  color: #8ff8ff;
  font-size: calc(1vw + 1vh + 0.5vmin);
  line-height: 1.3;
  font-weight: 100;
  text-align: center;
  width: 50vw;
  margin-block-start: 0em;
  margin-block-end: 0em;

  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
}

.exp1 {
  background-color: rgba(255, 255, 255, 0.025);
  padding: 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.099);
  border-radius: 10px;
}

.exp {
  height: 100px;
  width: auto;
}

.forte-text {
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
}
.about-me img {
  width: auto;
  height: 40rem;
}

.forte-text h2 {
  font-family: "EditorialItalic", serif;
  color: white;
  font-size: calc(4vw + 4vh + 4vmin);
  margin-block-end: 0em;
  margin-block-end: 0em;
  font-weight: 100;
}

.sticky-container {
  position: sticky;
  top: 50%; /* Keeps the section fixed in the middle */
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sticky-container.release {
  position: relative;
  top: auto;
  transform: none;
}

.forte-text {
  z-index: 1;
}

.lottie-container {
  position: absolute;
  z-index: 2;
  margin-top: 0px;
  width: 800px;
    height: auto;
}

.peace2 {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.peace h1 {
  font-family: "DrukPort", sans-serif;
  font-size: calc(4vw + 4vh + 6vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: white;
}

.peace p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: "RooberLight", sans-serif;
  color: rgba(255, 255, 255, 0.651);
  font-size: calc(0.5vw + 0.8vh + 0.5vmin);
  line-height: 1.3;
  width: 60%;
}

.marquee-about {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 50s linear infinite;
}
.marquee-about p {
  display: block;
  margin-block-start: 0.1em;
  margin-block-end: 0.0001em;
}
.marquee-about p h1 {
  font-size: calc(2vw + 5vh + 5vmin);
  letter-spacing: 0.8px;
  color: rgb(255, 255, 255);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.marquee-about p {
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.marquee-about2 {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 40s linear infinite;
}
.marquee-about2 p {
  display: block;
  margin-block-start: 0.1em;
  margin-block-end: 0.0001em;
}
.marquee-about2 p h1 {
  font-size: calc(2vw + 5vh + 5vmin);
  -webkit-text-stroke: 1px;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.8px;
  color: rgb(255, 255, 255);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.marquee-about2 p {
  display: inline-block;
}

.marquee-about3 {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 30s linear infinite;
}
.marquee-about3 p {
  display: block;
  margin-block-start: 0.1em;
  margin-block-end: 0.0001em;
}
.marquee-about3 p h1 {
  font-size: calc(2vw + 5vh + 5vmin);
  letter-spacing: 0.8px;
  color: rgb(255, 255, 255);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.marquee-about3 p {
  display: inline-block;
}
.circle3 {
  height: 400px;
  width: 400px;
  background-color: #7140f2;
  opacity: 0.26;
  left: 50px;
  top: 100px;
  border-radius: 50%;
  position: absolute;
  filter: blur(100px);
  z-index: -99999999;
}
.circle4 {
  top: 0;
  height: 800px;
  width: 600px;
  position: absolute;
  left: 0px;
  background-color: #0055ff3a;
  filter: blur(100px);
  opacity: 0.26;
}
.circle5 {
  height: 800px;
  width: 600px;
  position: absolute;
  right: 0px;
  background-color: #0055ff59;
  filter: blur(100px);
  opacity: 0.26;
  top: 0;
}

.about-block {
  position: relative;
}
.about-image {
  position: absolute;
  height: 95%;
  max-width: 600px;
  top: 17px;
  right: 15px;
  border-radius: 14px;

  overflow: hidden;
}
.about-me {
  display: flex;
  align-items: center;
  gap: 60px;
  flex-direction: row;
  padding: calc(4vw + 4vh + 3vmin);
}

.about-me h2 {
  font-family: "HostLight";
  color: #8ff8ff;
  font-size: calc(1vw + 1vh + 1vmin);
}

.about-me p {
  font-family: "HostLight";
  font-weight: 100;

  color: rgba(255, 255, 255, 0.564);
  font-size: calc(0.6vw + 0.6vh + 0.6vmin);
}

.about-text {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  margin-bottom: 80px;
}
.about-text p {
  font-family: "RoobertLight";
  font-size: calc(0.6vw + 0.4vh + 0.5vmin);
  -webkit-line-clamp: 8;
  line-clamp: 8;
  width: 40%;
  color: white;
  line-height: calc(0.7vw + 1vh + 1vmin);
  font-size: calc(0.5vw + 0.5vh + 0.8vmin);
  /* z-index: 99999999; */
}

.about-text h3 {
  color: #ffffff;
  font-size: calc(1vw + 1.5vh + 0.5vmin);
  font-family: "RoobertLight";

  width: 25vw;

  letter-spacing: 0.2px;
  text-transform: uppercase;
  /* z-index: 99999999; */
}
.about-block p {
  color: rgb(187, 184, 214);
  font-size: calc(0.6vw + 0.4vh + 0.5vmin);
  font-family: "RoobertLight";
  position: relative;
  width: 30vw;
  left: 20px;
  top: -20px;
  line-height: calc(1.2vw + 0.4vh + 0.5vmin);
}
.about-block h4 {
  color: #b8abc9;
  font-size: calc(0.8vw + 0.4vh + 0.5vmin);
  font-family: "RoobertLight";
  position: relative;
  width: 80vw;
  left: 20px;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
}
.about-block h2 {
  color: #e268af;
  font-size: calc(1.4vw + 0.4vh + 0.5vmin);
  font-family: "RoobertLight";
  position: relative;
  width: 80vw;

  margin-block-start: 0.1em;
  margin-block-end: 1em;
}

.about-block a {
  color: #e268af;
  text-decoration: none;
  letter-spacing: 0.4px;
  cursor: url("../Assets/cursor.svg"), auto;
}

@media screen and (min-device-width: 312px) and (max-device-width: 800px) {
  .about-div {
    width: 100%;

    padding-left: 20px;
  }

  .about-page {
    overflow-x: hidden;
  }

  .lottie-container {
    position: absolute;
    z-index: 2;
    margin-top: 0px;
    width:350px;
      height: auto;
  }
  .text-header-about {
    margin-top: 80px;
    position: relative;
    padding-left: 20px;
    position: relative;
    width: 90%;
    top: 50%;
    transform: translateY(100%);
  }

  .about-div p {
    color: white;
    font-size: calc(1.5vw + 1.5vh + 2vmin);
    font-family: "RoobertLight", sans-serif;
    width: 95%;
    margin-block-start: 1.5em;
    margin-block-end: 0em;
    line-height: 1.3;
  }

  .about-div {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 95%;
  }
  .about-image {
    top: 15px;
    position: relative;
    height: 350px;
    width: 93%;
    margin-top: 0px;
    border-radius: 15px;
  }
  .about-block p {
    font-size: 16px;
    margin-block-start: 0.4em;
    margin-block-end: -0.4em;
    left: 15px;
    line-height: 26px;
  }
  .about-block h3 {
    font-size: 26px;
    left: 15px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  .about-block h2 {
    font-size: 28px;
  }
  .about-block h4 {
    font-size: 20px;
  }
  .about-heading {
    font-size: 40px !important;
    width: 80%;
    margin-block-start: 0.8em;
    margin-block-end: 1em;
    left: 10px;
  }
  .about-block h4 {
    left: 15px;
    margin-block-start: 0.8em;
    margin-block-end: 1em;
  }

  .about-text {
    position: relative;
    display: flex;
    flex-wrap: none;
    flex-direction: column;
    width: 95vw;
    justify-content: space-around;
  }
  .about-text p {
    font-family: "Roobert-Regular";
    line-clamp: 8;
    -webkit-line-clamp: 8;
    width: 100%;
    color: rgba(255, 255, 255, 0.766);
    line-height: calc(1vw + 2vh + 2vmin);
    font-size: calc(1.5vw + 1vh + 1.55vmin);
    /* z-index: 99999999; */
    margin-block-end: 0.2em;
    margin-block-start: 0.1em;
  }

  .about-me h2 {
    font-family: "HostLight";
    color: #8ff8ff;
    font-size: calc(2vw + 1.4vh + 1vmin);
    font-weight: 100;
} 
.about-me p {
  font-family: "HostLight";
  
  font-size: calc(1vw + 1vh + 1vmin);
  font-weight: 100;
} 
.peace_of_mind img{
  width: auto;
  height: 200px;
  padding-right: 20px !important;

}

.peace_of_mind p {
  font-family: "HostLight", sans-serif;
  font-weight: 100;
  color: white;
  margin: auto;
  width: 90%;
  font-size: calc(1vw + 1vh + 1vmin);
  line-height: calc(1.5vw + 1.5vh + 2vmin);
  text-align: center;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
}
.peace_of_mind {
  background-repeat: no-repeat;
  background-position: 90px;
  background-size: contain;
}
  .about-text h3 {
    color: #ffffff;
    line-height: calc(5vw + 2vh + 3vmin);
    font-size: calc(3vw + 2vh + 3vmin);
    font-family: "RoobertLight";

    width: 90%;

    letter-spacing: 0.2px;
    text-transform: uppercase;
    /* z-index: 99999999; */
  }
  .about {
    margin-bottom: 20vh;
  }
 
  .circle4 {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 150px;
    background-color: #9640f2;
    filter: blur(100px);
    opacity: 0.26;
    top: 0px;
  }
  .exp-images {
    margin-bottom: 50px;
    width: 95vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .expertise {
    display: block;
    grid-gap: 40px;
    gap: 20px;
    height: fit-content;
    margin-top: 10rem;
    margin-bottom: 20rem;
    padding: 0px 20px;
  }
  .release {
    position: relative; /* Change position after animation finishes */
    height: auto;
    overflow-y: auto; /* Allow scrolling after release */
  }
  

  .sticky-container {
    position: sticky;
   top: 50%; 
    transform: translateY(0%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  .expertise h1 {
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
    font-size: calc(3.5vw + 3.5vh + 3.5vmin);
  }
  .expertise p {
    margin-block-start: 0.1em;
    font-size: calc(2vw + 1vh + 01vmin);
    width: 100%;
  }
  .ng-about {
    display: none;
  }
  .ng-about2 {
    display: none;
  }
  .head-exp {
    font-size: calc(4vw + 4vh + 4vmin);
    padding-left: 20px;
  }
  .text-header-work {
    margin-top: 60px;
    padding-left: 10px;
    font-size: calc(4vw + 4vh + 4vmin);
  }
  .tooltip .tooltiptext {
    font-family: "RoobertLight", sans-serif;
    visibility: hidden;
    position: absolute;
    width: 200px;
    text-align: left;
    padding: 10px 15px;
    border-radius: 12px;
    z-index: 1;
    line-height: 1.3em;
    opacity: 0;
    transition: opacity 0.3s;
    border: 2px solid rgba(255, 255, 255, 0.085);
  }
  .header-para-about h1 {
    margin-block-start: 0em;
    font-size: calc(4.5vw + 4.5vh + 4.5vmin);
    line-height: calc(4.5vw + 5vh + 5vmin);
  }
  .company {
    width: auto;
    height: 70px;
  }
  .company-images {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    padding-left: 15vh;
    padding-right: 15vh;
    padding-top: 50px;
  }
  .company-images:active .company {
    opacity: 1;
    transition: 200ms ease;
}
.case-study-main{
  padding-left: 10px;
  padding-right:12px;
}
.bg-grad{
  display: none;
}

.menu-test{
  display: none;
}

.about-span {
  height: 80vh;

}
.about-bg {
  background-size: cover;
  background-position-x: 50rem;
  background-image: url(https://ik.imagekit.io/harshitsharma/about/about_bg_Kh3AoUvcW.svg?updatedAt=1725473180607);
}
.about-me {
  display: flex;
  align-items: center;
  grid-gap: 60px;
  gap: 60px;
  flex-direction: column;
 padding-left: 20px;
 padding-right: 20px;
}
.about-me img {
  width: 100%;
  height: auto;
}

}
