.nav-list {
  list-style-type: none;
  font-family: "HostBold", sans-serif;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9999999;
}

.nav.colorChange {
  background-image: linear-gradient(#0055ff19, #0055ff00);
  transition: all 0.5s ease-out;
  backdrop-filter: blur(1vh);
  
 
}
.logo {
  top: 20px;
  width: calc(1vw + 3vh + 3vmin);
  height: auto;
  margin-right: 3vh;
}

.nav {
  background-image: linear-gradient(#0055ff19, #0055ff00);
  height: calc(3vw + 2vh + 2vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  mix-blend-mode: difference;
  /* Fix your navbar by using below two lines of code */
  position: sticky;
  top: 0;
  /* Fix your navbar by using above two lines of code */
  z-index: 9999999999;

  transition: all 0.5s ease-out;
}
.szh-menu{
  background-color: #0d0d17f2 !important;
  color: white;
  font-family: "HostRegular", sans-serif;
  min-width: 20rem !important;
  z-index: 999999966 !important;
  font-size: calc(2vw + 2vh + 3vmin);
  border-radius: 20px !important;
 
border: 1px solid rgba(245, 245, 245, 0.097) !important;
}


.text-nav {
  top: 20px;
  width: calc(10vw + 10vh + 10vmin);
  height: auto;
  margin-right: 3vh;
  z-index: 99999;
}
.text-nav2{
  display: none;
  }

.logo-h {
  top: 20px;
  width: calc(4vw + 5vh + 5vmin);
  height: auto;
  left: 0px;
}
.break-nav {
  display: none;
}

@font-face {
  font-family: "Roobert-Medium";
  src: url(../Assets/Fonts/Roobert-Medium.woff2);
}

@font-face {
  font-family: "Roobert-Regular";
  src: url(../Assets/Fonts/Roobert-Regular.woff2);
}

ul.nav-list li {
  display: inline;
}

ul {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  z-index: 9999999;
}

.nav-list li a {
  text-decoration: none;
  text-align: center;
  padding: 2px;
  font-size: calc(1vw + 0.5vh + 0.5vmin);
  cursor: url("../Assets/cursor.svg"), auto;
}
.navbar-container {
  padding-top: 10px;
}
.navbar-container-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-list-2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.menu{
  display: none;
}

.nav-list-2 p {
  width: 150px;

  margin-block-start: 0em;
  margin-block-end: 0em;
}

.nav-list:hover li {
  opacity: 0.4;
  transition: 200ms ease;
  cursor: url("../Assets/cursor.svg"), auto;
}
.nav-list li:hover {
  opacity: 1;
  transition: 200ms ease;
  cursor: url("../Assets/cursor.svg"), auto;
}

@media screen and (max-device-width: 800px) {

  .navbar-container-nav {
    width: 100%;
    display: flex;
 
    justify-content: space-between;
   
   
}
.text-nav {
  display: none;
}

  .logo {

    top: 10px;
    width: calc(5vw + 4vh + 5vmin);
    height: auto;
    padding-right: 10vh;
  
  }
  .nav-list {
    list-style-type: none;
    padding-inline-start: 0px;
    font-family: "Roobert-Regular";
    position: relative;
    overflow: hidden;
    display: block;
    text-align: center;
    /* z-index: 99999999; */
  }
  .nav {
    background: transparent;
    height: calc(5vw + 5vh + 4vmin);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 9999999999;
    transition: all 0.5s ease-out;
    padding: 10px 10px;
  }
.text-nav2{
top: 20px;
  width: calc(15vw + 15vh + 15vmin);
  height: auto;
  margin-right: 1vh;
  z-index: 99999;
  display: block;
}
.menu{
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  mix-blend-mode: exclusion;
  z-index: 99999999;
}

  .logo-h {
    display: none;
  }
  .nav-list li a {
    display: none;
  }
  .break-nav {
    display: inline;
    opacity: 0.5;
  }

  a.dumpyard {
    display: none;
  }
  a.about {
    z-index: 99999999;
  }
  a.work {
    z-index: 99999999;
  }
  a.connect {
    z-index: -99999999;
  }

  
}
