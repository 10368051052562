.container {
padding-top: 10vh;
  background: white;
  padding-bottom: 20vh;
}

.container-other {
padding-top: 10vh;

  padding-bottom: 30vh;
}


.container-other h1{
  color: white;
  }

.work-content {
  width:20%;
  
}
.work-div {
  width: 48%;
  height: auto;
display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
 
}
.work-div a{
  width: 100%;
  text-decoration: none;
}


.work-image {
  width: 100%;
  
  height: calc(15vw + 10vh + 10vmin);

}

.work-container {
  position: relative;
  top: 5vh;
  width: 85%;
  display: flex;
  gap: calc(1.5vw + 1.5vh + 1.5vmin);
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #080117, hsla(258, 92%, 5%, 0.041));
  border-radius: 10px;
}

.container h2 {
  width: 550px;
  font-family: "DrukPort", sans-serif;
  color: #121222;
  font-size: calc(10vw + 10vh + 10vmin);

  margin-block-start: 0.4em;
  margin-block-end: 0em;
  -webkit-text-stroke-width: 1px;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  font-style: normal;
}

.work-div {
  max-width: 100vw;
  position: relative;
  margin-bottom: 60px;
}

.desc-div-main{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.work-content img{
  width: 100%;
   height: auto;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
}


.work-div h1 {
  z-index: 999999;
}
.work-title {
  position: absolute;
  font-family: "Roobert-Medium", sans-serif;
  color: white;
  font-size: calc(2vw + 1.5vh + 1.5vmin);

  margin-block-start: 0em;
  margin-block-end: -0.1em;
  bottom: 20px;
  left: 20px;
}
.desc-div{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}
.desc-div2{
  display: flex;
  justify-content: space-between;
  width: 100%;
  
}
.work-outcome{
  font-size: calc(0.6vw + 0.5vh + 0.5vmin) !important ;
  color: rgba(0, 0, 0, 0.7) !important;
  margin-block-start: 0.5em;
  font-family: "HostLight";

    margin-block-start: 0em !important;

  
margin-bottom: 20px;
}

.desc-div2 p{
color: white;
font-family: "HostLight";

margin-block-start: 0.5em;
margin-block-end: 0em;
font-size: calc(0.6vw + 0.8vh + 0.5vmin);
line-height: calc(1vw + 1vh + 1vmin);
}


.desc-div p{
  font-family: "HostLight";
  color: #121222;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: calc(0.6vw + 0.8vh + 0.5vmin);
  line-height: calc(1vw + .9vh + .8vmin);
  
}




.nda {
  position: absolute;

  width: calc(4vw + 4vh + 4vmin);
  height: auto;
  top: 20px;
  right: 20px;
  z-index: 9999999;
}

.work-div:hover .grad-overlay {
  opacity: 1;
}


/* .work-image {
  height: inherit;
  max-width: 36vw;
  border-radius: 20px;
  width: 50%;
  position: absolute;
  right: 15px;
  overflow: hidden;
} */

.work-image-image {
  object-fit: cover;
  width: 40vw;
  height: 50vh;
}

.password-input {
  background-color: rgba(21, 8, 45, 0);
  border: 1px solid rgba(212, 212, 212, 0.322);
  padding: 15px 10px;
  border-radius: 100px;
  font-family: "Roobert-Regular";
  color: rgba(255, 255, 255, 0.705);
  font-size: calc(0.6vw + 0.4vh + 0.5vmin);
  width: 350px;

  transition: opacity 0.2s ease-in-out;
}
.tags-cs {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.container-div1 h1 {
  font-family: "EditorialItalic", serif;
  color: #121222;
  font-size: calc(3vw + 3vh + 1vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
padding-left: calc(4vw + 4vh + 3vmin);
  font-weight: 400;
  font-style: normal;

}


.container-div3 h1 {
  font-family: "EditorialItalic", serif;
  color: #ffffff;

  font-size: calc(3vw + 3vh + 1vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
padding-left: calc(4vw + 4vh + 3vmin);
  font-weight: 400;
  font-style: normal;
}
.work-div1 h1 {
  font-family: "EditorialLight", sans-serif;
  color: #0057FF;
  
  font-size: calc(3vw + 3vh + 1vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-weight: 400;
  font-style: normal;

}
.work-div1 p {
  font-family: "HostLight";
  color: #000000;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: calc(0.6vw + 0.6vh + 0.5vmin);
  line-height: calc(1vw + 1vh + 1vmin);
  width: 80%;
  z-index: 9999999;
}
.container-div2{
  position: relative;
  width: 100vw;
}
.bg{
  position: absolute;
  bottom: calc(15vh + 10vmin);
  width: 100vw;
  z-index: -9999;
}


@media (min-height: 2001px) {
  .bottom-tag {
      bottom: calc(-34vh + 10vmin); /* Customize this calculation if needed */
  }
}


.container-div2 h1 {
      font-family: "EditorialLight", serif;

  color: #ffffff45;

  font-size: calc(2.5vw + 2.5vh + 1vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
 text-align: center;
  font-weight: 200;
  font-style: normal;
}
.container-div2 span {
  font-family: "EditorialItalic", serif;
}
.bg-grad img {
 
  width: 100vw;
}

.container-div1 p {
  font-family: "Roobert-Regular";
  color: #121222;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: calc(0.6vw + 0.6vh + 0.5vmin);
  line-height: calc(1vw + 1vh + 1vmin);
  padding-left: calc(4vw + 4vh + 3vmin);
  width: 40%;
  z-index: 9999999;
}

.container-div3 p {
  font-family: "Roobert-Regular";
  color: #ffffff;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: calc(0.6vw + 0.6vh + 0.5vmin);
  line-height: calc(1vw + 1vh + 1vmin);
  padding-left: calc(4vw + 4vh + 3vmin);
  width: 40%;
  z-index: 9999999;
}

.container-div2 p {
  font-family: "Roobert-Regular";
  color: #ffffff;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: calc(0.6vw + 0.6vh + 0.5vmin);
  line-height: calc(1vw + 1vh + 1vmin);
  padding-left: calc(4vw + 4vh + 3vmin);
  width: 40%;
}

.tags {
  width: 40%;
  left: 25px;
  position: relative;
  margin-bottom: 10px;
}

.tag {
  padding: 10px 20px;
  border: 1px solid #7140f2;
  background-color: transparent;
  font-family: "Roobert-Regular";
  font-size: calc(0.6vw + 0.5vh + 0.6vmin);
  color: #bcb2d6;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: url("../Assets/cursor.svg"), auto;
}
.tag-0 {
  background-color: #ff7eac;
}
.tag-1 {
  background-color: #744ba8;
}
.tag-2 {
  background-color: #4db47c;
}
.tag-3 {
  background-color: #ed7a56;
}
.tag-4 {
  background-color: #6147fb;
}
.tag-5 {
  background-color: #de4d67;
}

.img-fluid {
  width: 100%;
  border-radius: 10px;
  height: auto;
  object-fit: cover;
}
.video-fluid-desc{
  width: 100%;
  border-radius: 10px;
  height: auto;
  object-fit: cover;
  border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
}

.read-or-hide {
  color: #ff7eac;
  cursor: url("../Assets/cursor.svg"), auto;
}

.work-list-swipe {
  position: relative;
  width: 95%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
.project {
  width: 99%;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: calc(16vw + 1vh + 1vmin);
  z-index: 88484;
}
.project-image-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.001s ease-in-out;
}

.project-logo {
  width: 80px;
  height: 80px;
  margin-left: 10px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.project-logo-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 585484;
}

.project-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  z-index: -1;
}

.project:hover .project-image-cover,
.project:hover .grad-overlay-sm,
.project:hover .project-description,
.project :hover {
  visibility: visible;
  opacity: 1;
  color: rgb(255, 255, 255);
  cursor: url("../Assets/caseStudy.svg") 10 10, auto;
}

.grad-overlay-sm {
  background-image: linear-gradient(45deg, #301651, #13082830);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.001s ease-in-out;
}

.container div {
  border-top: 0px dashed white !important;
}
.container div p[style] {
  display: none;
}

.project-description {
  font-size: calc(1vw + 0.5vh + 1vmin);
  color: rgb(196, 196, 196);
  font-family: "Roobert-Regular";
  width: 95%;
  position: absolute;
  z-index: 8888;
  left: 15px;
  bottom: -10px;
}

.AWF{ 
  transition: all 0.1s linear;
  top: 15px;
  position: relative;
 
}

.AWF:hover{ 
  transform: translateY(-8px);
}


.arrow {
  padding-left: 10px;
  width: 40px;
  color: rgb(242, 126, 126);
  transition: all 0.1s linear;
  top: 15px;
  position: relative;
}
.arrow:hover {
  transform: translateX(8px);
}
:root {
  --height: 0px;
}

@media screen and (min-device-width: 312px) and (max-device-width: 800px) {
 .work-container{
  width: 95%;
  gap: 20px;
 }
  .work-div {
    max-width: 95vw !important;
    width: 100%;
    margin: 0 auto;
    height: 300px !important;
    position: relative;
    margin-bottom: 20px;
    object-fit: cover;
    text-align: center;
  }
  .work-div1 h1{
    font-size: calc(4vw + 3vh + 4vmin);
  }
  .work-div1 p{
    font-size: calc(1.2vw + 1vh + 0.5vmin);
  line-height: calc(1.5vw + 1.2vh + 1.2vmin);
  margin-block-end: 2em;

  }

  .nda {
    position: absolute;
    width: calc(6vw + 6vh + 6vmin);
    height: auto;
  }
  .magic {
    display: none;
  }

  .case-study-main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .case-study {
    position: relative;
    width: 100%;
    border-radius: 16px;
    height: auto;
    padding: 0;
  }
  .fill {
    width: 100%;
    height: auto;
    background-color: #7140f2;
  }
  .split {
    width: 100%;
  }

  .img-fluid {
    border-radius: 5px;
  }

  .work-image-image {
    object-fit: cover;
    width: 100%;
    height:250px;;
  }

  .overlay{
    height:250px;
  }
  .work-image {
    width: 100%;
    height:250px;
    border-radius: 15px;
  }

  .work-content p {
    width: 100%;
    font-size: calc(2vw + 2vh + 2.5vmin);
    margin-top: 10px;
  }
.container{
  padding-bottom: 10vh;
}
.container-div2 h1{
  font-family: "EditorialItalic", serif;
  font-size: calc(3vw + 3vh + 3vmin);
  color: #ffffff;
 

  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-left:10px;
  font-weight: 400;
  font-style: normal;
}

.container-div2 p {
  font-family: "Roobert-Regular",sans-serif;
  color: #ffffff;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: calc(1.2vw + 1vh + 1.2vmin);
  line-height: calc(1.5vw + 1.5vh + 1.2vmin);
  padding-left: 10px;
  width: 90%;
  z-index: 9999999;
}

  .work-title {
    font-size: calc(2.5vw + 2.5vh + 2.5vmin);
    bottom: 70px;
  }
  .desc-div p {
    font-family: "Roobert-Regular", sans-serif;
    color: #121222;
    margin-block-start: 0.5em;
    margin-block-end: 0em;
    text-align: left;

    font-size: calc(1.2vw + 1vh + 1.2vmin);
    line-height: calc(1.5vw + 1.2vh + 1.2vmin);
  }

  .desc-div2 p {
    font-family: "Roobert-Regular", sans-serif;
    color: #ffffff;
    margin-block-start: 0.5em;
    margin-block-end: 0em;
    text-align: left;
    font-size: calc(1.2vw + 1vh + 1.2vmin);
    line-height: calc(1.5vw + 1.2vh + 1.2vmin);
  }

  .tags {
    width: 90vw;
  }
  .container-div1 h1 {
   
    color: #121222;
    font-family: "EditorialItalic", serif;
    font-size: calc(3vw + 3vh + 3vmin);
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-left:10px;
    font-weight: 400;
    font-style: normal;
}

.container-div3 h1 {
   
  color: #ffffff;
  font-family: "EditorialItalic", serif;
  font-size: calc(3vw + 3vh + 3vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-left:10px;
  font-weight: 400;
  font-style: normal;
}



.container-div1 p {
  font-family: "Roobert-Regular",sans-serif;
  color: #121222;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: calc(1.2vw + 1vh + 1.2vmin);
  line-height: calc(1.5vw + 1.5vh + 1.2vmin);
  padding-left: 10px;
  width: 95%;
  z-index: 9999999;
}

.container-div3 p {
  font-family: "HostLight",sans-serif;
  color: #ffffff;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: calc(1.2vw + 1vh + 1.2vmin);
  line-height: calc(1.5vw + 1.5vh + 1.2vmin);
  padding-left: 10px;
  width: 95%;
  z-index: 9999999;
}
.container-other {
  padding-top: 8vh;
  padding-bottom: 15vh;
}
.company-images {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  padding-left: 4vh !important;
  padding-right: 4vh !important;
  padding-top: 100px;
  margin-bottom: 20rem;
}
.tooltip-bottom {
  top: 135%;
  margin-left: -20px;
}
.AWF{ 
 padding-left: 20px;
}
}

@media only screen and (min-width: 800px) and (max-width: 1026px){

  .work-container {
   
    grid-gap: calc(1.5vw + 0.5vh + 1.5vmin);
    gap: calc(1.5vw + 0.5vh + 1.5vmin);
 
}
.header-image {
  width: calc(20vw + 20vh + 20vmin);
  height: auto;
  right: 5%;
}


.intentions-para img {
  height: auto;
  width: 300px;
}
.header-para h1 {
 
  font-size: calc(3vw + 3vh + 3vmin);
  line-height: calc(3vw + 3vh + 4vmin);

}
.intentions-head h1 {

  font-size: calc(2vw + 2.5vh + 3vmin);

  line-height: calc(2.5vw + 3vh + 3vmin);
}
.get-in-touch h2 {
  font-size: calc(4vw + 5vh + 5vmin);
  font-family: "EditorialLight", sans-serif;
  margin-block-start: 0.1em;
  margin-block-end: 0em;
  font-weight: 400;
  padding-left: calc(4vw + 4vh + 3vmin);
  font-style: normal;
}
.bg {
  bottom: calc(20vh + 15vmin);
}
.company {
  width: auto;
  height: 80px;
}
.container-div1 h1 {

  padding-left: calc(3vw + 4vh + 3vmin);
  
}
.container-div1 p {

  padding-left: calc(3vw + 4vh + 3vmin);
  
}
.bg-grad img {
 
  width: 100vw;
}
.menu-test p {
 
  font-size: 12px;
}
.menu-test ul li a {

  font-size: .8rem;
  
}
.menu-test ul li {
  margin-bottom: 7px;
}
.dumpyard {
  padding-left: calc(3vw + 3vh + 3vmin);
  width: fit-content;
  max-width: 1200px;
}
.dumpyard-container {
display: flex;
justify-content: center;
align-items: start;
flex-direction: column;
width:fit-content;
padding: 20px;
margin-bottom: calc(10vw + 10vh + 10vmin);
}
.responsive-grid {

width: 86%;

}
}

@media only screen and (min-width: 1026px) and (max-width: 1324px) {
    .bg {
      bottom: calc(16vh + 13vmin);
    }
    .work-container {
   
      grid-gap: calc(1.5vw + 0.5vh + 1.5vmin);
      gap: calc(1.5vw + 0.5vh + 1.5vmin);
   
  }
  .header-image {
    width: calc(20vw + 20vh + 20vmin);
    height: auto;
    right: 5%;
  }
  
  
  .intentions-para img {
    height: auto;
    width: 300px;
  }
  .header-para h1 {
   
    font-size: calc(3vw + 3vh + 3vmin);
    line-height: calc(3vw + 3vh + 4vmin);
  
  }
  .intentions-head h1 {
  
    font-size: calc(2vw + 2.5vh + 3vmin);
  
    line-height: calc(2.5vw + 3vh + 3vmin);
  }
  .get-in-touch h2 {
    font-size: calc(4vw + 5vh + 5vmin);
    font-family: "EditorialLight", sans-serif;
    margin-block-start: 0.1em;
    margin-block-end: 0em;
    font-weight: 400;
    padding-left: calc(4vw + 4vh + 3vmin);
    font-style: normal;
  }
  .menu-test p {
 
    font-size: 12px;
  }
  .menu-test ul li a {
  
    font-size: .8rem;
    
  }
  .menu-test ul li {
    margin-bottom: 7px;
  }
  .company {
    width: auto;
    height: 80px;
  }
  .container-div1 h1 {
  
    padding-left: calc(3vw + 4vh + 3vmin);
    
  }
  .container-div1 p {
  
    padding-left: calc(3vw + 4vh + 3vmin);
    
  }
  .dumpyard {
    padding-left: calc(3vw + 3vh + 3vmin);
    width: fit-content;
    max-width: 1200px;
 }
 .dumpyard-container {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width:fit-content;
  padding: 20px;
  margin-bottom: calc(10vw + 10vh + 10vmin);
}
.responsive-grid {
 
  width: 86%;
  
}
}

@media only screen and (max-width: 800px) {
  .responsive-grid {
 
    width:100%;
    
}
.dumpyard-container {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width:92%;
  padding: 20px;
  margin-bottom: calc(10vw + 10vh + 10vmin);
}
}

@media only screen and (min-width: 1026px) and (max-width: 1196px) {
  .bg {
    bottom: calc(20vh + 17vmin);
  }
}