.dumpyard-sec {
  text-align: left;
  width: 95%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 110vh;
  margin-bottom: 150px;
 
}

.dumpyard-div-img {
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
}
.dumpyard-div-img2{
 display: none;}

.dump-div {
  height: 55vh;
  background-color: rgb(113, 64, 242);
  width: 100%;
  border-radius: 20px;
}
.dump-text {
  position: absolute;
  font-family: "BasementGrotesk";
  font-size: calc(2vw + 2vh + 1vmin);

  color: white;
  text-align: left;
  top: 20px;
  left: calc(2vw + 1vh + 1vmin);
  width: 35%;
}
.dump-div p {
  position: absolute;
  top: 16vh;
  line-height: calc(0.9vw + 0.4vh + 0.5vmin) !important;
  left: calc(2vw + 1vh + 1vmin);
  font-family: "Roobert-Regular";
  color: white;
  font-size: calc(0.6vw + 0.4vh + 0.5vmin);
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 35%;
}
.dump-btn {
  display: flex;
  gap: 15px;
  position: absolute;
  bottom: 40px;
  left: calc(2vw + 1vh + 1vmin);
  
}


.button1:hover {
  color: #7140f2;
  background-color: white;
  cursor: url('../Assets/cursor.svg'), auto;
}

@media screen and (max-device-width: 1400px) {
  .dumpyard-div-img {
    height: 100%;
  }
  .dump-div {
    height: 55vh;
    background-color: rgb(113, 64, 242);
    width: 100%;
    border-radius: 20px;
  }
  .dump-div p {
    font-size: 16px;
    width: 50%;
    top: 35%;
    line-height: 22px !important;
  }
}


@media screen and (min-device-width: 601px) and (max-device-width: 1210px) {
 
 

  .dump-div {
    height: 340px;
    background-color: rgb(113, 64, 242);
    width: 100%;
    border-radius: 20px;
  }

}

@media screen and (min-device-width: 312px) and (max-device-width: 600px) {
 .dumpyard-div-img2{
   position: relative;
   display: block;
   width: 100%;
  
 }
  .dumpyard-sec {
    display: flex;
   
    max-width: 100%;
    width: 100%;
    margin-top: 110vh;
    margin-bottom: 100px;
}
  .dumpyard-div-img {
    display: none;
  }
  .dump-div {
    
    position: relative;
    height:42rem;
    background-color: rgb(113, 64, 242);
    border-radius: 20px;
  }
.dumpyard p{
  line-height: calc(1vw + 2vh + 2vmin);
    font-size: calc(1.5vw + 1vh + 1.55vmin);
}

  .dump-text {
    font-size: calc(2.2em + 1vw);
   left: 20px;
   margin-block-start: 0em;
   margin-block-end: 0em;
   position: relative;
  }
  .dump-div p {
    font-size: 16px;
    width: 80%;
    left: 20px;
    margin-block-start: 1.5em;
   margin-block-end: 0em;
    position: relative;
    line-height: 20px !important;
  }
  .dump-btn {
    width: 100%;
    display: flex;
    bottom: 40px;
    gap:0px;
 
    left: 20px;
    font-size: 20px;
    
  }
  .button1 {
    font-size: 15px;    
    padding: 14px 20px;
    margin-right:15px ;
  }
 
}
