.scrollTop {
  position: fixed;

  bottom: 20px;
  left: 20px;
  height: 50px;

  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 1;
  mix-blend-mode: exclusion;
}

.key-fact{
  display: flex;
  width: 100%;
  flex-direction: column;
padding: 20px 20px 20px 20px;
background-color: rgba(255, 255, 255, 0.05);
border: 1px solid rgba(255, 255, 255, 0.081);
border-radius: 12px;
margin-top: 20px;
margin-bottom: 20px;
}

.key-fact p{
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: rgb(255, 255, 255);
  font-family: "HostLight",sans-serif;
  font-size: calc(.6vw + .5vh + .5vmin);
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.3;
  
}
.key-fact span{
  font-size: calc(.6vw + .5vh + .5vmin);
display: flex;
align-items: center;
margin-bottom: 20px;
}

.key-fact h3{
  margin-block-start: 0em;
  margin-block-end: 0.1em;
  color: white;
  font-family: "HostLight",sans-serif;
  font-size: calc(.6vw + .6vh + .6vmin);
  font-weight: 100;
  line-height: calc(.8vw + 1vh + .8vmin);
}


.pattern {
  position: absolute;

  bottom: 0px;
  right: 0px;
  height: 500px;
display: none;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
}

.break {
  margin-top: 20px;
  margin-bottom: 20px;
}

.video-container{
  display: flex;
  gap: 0px;
  flex-direction: column;

}
.video-desc{
  display: flex;
  background-color: white;
  padding: 16px;
  flex-direction: column;
  object-fit: cover;
  border-end-end-radius: 12px;
  border-bottom-left-radius: 12px;
}



.video-desc h1{
  width: 100%;
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: "HostMedium",sans-serif;
  font-weight: 500;
}

.text-nda-1 {
  width: 100%;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-family: "Roobert-Medium";
  font-size: calc(1vw + 1vh + 1vmin);
  text-align: center;
  margin-block-start: 0em;
  margin-block-end: 0.1em;
  transition: all 1.5s linear;
}
.text-nda-2 {
  width: 100%;
  color: rgba(255, 255, 255, 0.41);
  font-family: "Roobert-Regular";
  font-size: calc(1vw + 0.5vh + 0.5vmin);
  text-align: center;
  margin-block-start: 0em;
  margin-block-end: 1em;
  transition: all 1.5s linear;
}
.scrollTop:hover {
  opacity: 1;
}
.nda-2 {
  width: calc(4vw + 5vh + 5vmin);
  /* transform: translate3d(0px, 0px, 0px);  */
  content-visibility: visible;
  margin-left: auto;
  margin-right: auto;
}

.navbar-container-nda {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(182, 182, 182);
  opacity: 1; /* Firefox */
  padding: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.nda-div {
  position: relative;
 
 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  /* Ensure it takes full viewport height */
  z-index: 99999;
  text-align: center;
}



.grad-overlay-sm-mw {
  position: absolute; /* Changed from absolute to relative for proper alignment */
  width: 100%;
  max-width: 600px; /* Set a max-width for better responsiveness */
  padding: 20px;
  margin-top: 30rem;
  border-radius: 20px;
  transition: opacity 0.2s ease-in-out;
}


.nda-container {
  position: relative;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.caseStudy-container {
  display: flex;
  width: 100%;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10rem;
  z-index: 1;
    position: relative;

}

.case-study-parent {
  width: 100%;
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
.thank-you {
  font-size: calc(2.5vw + 2.5vh + 2.5vmin);
  font-family: "DrukPort", sans-serif;
  text-transform: uppercase;
  color: white;
  font-weight: 400;
  font-style: normal;
}
.thank-you-div {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 50px;
  position: relative;
 margin-left: auto;
 margin-right: auto;
 margin-bottom: 20rem;
}
.thank-you-div img{
  width: 20%;
}




.thank-you-div h2 {
  font-family: "Roobert-Regular";
  color: rgb(255, 255, 255);
  float: left;
  width: 30%;
  line-height: calc(1vw + 2vh + 0.6vmin);
  font-size: calc(0.8vw + 0.5vh + 0.6vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
  text-transform: uppercase;
}

.thank-you-div h1 {
  font-family: "EditorialItalic", serif;
  font-size: calc(2vw + 1.8vh + 3vmin);

  color: white;
  font-weight: 400;
  font-style: normal;
}

.team {
  color: rgba(255, 255, 255, 0.212);
  display: flex;
  align-items: center;
  gap: 10px;
  
}


.live{
  width: 40px;
  height: auto;
}



.ng-next {
  width: 100%;
}
.next-projects {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}
.projects {
  width: 48%;
  height: 400px;
  background-color: #9cff8c;
}

.team p {
  color: rgb(255, 255, 255);
  text-decoration: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.date-launch {
  font-family: "Roobert-Regular";
  font-size: calc(0.6vw + 0.4vh + 0.5vmin);
  display: flex;
  align-items: center;
  width: 100%;
  gap: 50px;
 
}
.ng-content-cs {
  display: block
  
}

.date-launch a {
  font-family: "Roobert-Regular";
  text-decoration: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  transition: all 200ms ease;
}

.date {
  display: inline-block;
  color: rgba(255, 255, 255, 0.212);
  display: flex;
  align-items: center;

  gap: 10px;
}
.date p {
  display: inline-block;
  color: white;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.case-study-child {
  width: 100%;
  max-width: 75vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
}

.heading-nda {
  position: relative;
  font-size: calc(6vw + 0.4vh + 0.5vmin);
  color: white;
  font-family: "EditorialLight", sans-serif;
  margin-block-start: 0em;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-block-end: 0em;
  font-weight: 100 ;
 
}

.case-study-heading {
  display: inline-block;
  position: relative;
  font-size: calc(1vw + 1vh + .6vmin);
  color: rgba(255, 255, 255, 0.316);
  font-family: "HostMedium", sans-serif;
  margin-block-start: 0em;
  -webkit-line-clamp: 3;
  width: 50%;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  font-weight: 400;
  font-style: normal;
}

.case-study-desc {
  font-family: "HostMedium",sans-serif;
  font-size: calc(2vw + 1vh + 1vmin);
  line-height: calc(1.6vw + 1.3vh + 1.5vmin);
  color: white;
  width: 95%;
  margin-block-start: 0em;
  margin-block-end: .8em;
  display: inline-block;
}


.description-CS {
  font-family: "RoobertLight";
  font-size: calc(0.6vw + 0.5vh + 0.54vmin);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  width: 100%;
  line-height: calc(1vw + 0.6vh + 0.6vmin);

  justify-content: space-between;
}
.cs-launch {
  font-family: "RoobertLight";
  font-size: calc(0.6vw + 0.5vh + 0.54vmin);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  -webkit-line-clamp: 8;line-clamp: 8;
  -webkit-box-orient: vertical;
  width: 100%;
  line-height: calc(1vw + 0.6vh + 0.6vmin);
  justify-content: space-between;
  display: flex;
}

.cs-launch a {
  width: 50%;
  text-transform: uppercase;
  color: #3075ff;
  font-size: calc(0.6vw + 0.6vh + 0.8vmin);
  display: inline-block;
}

.description-tags {
  font-family: "RoobertLight";
  font-size: calc(0.6vw + 0.5vh + 0.54vmin);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  width: 100%;
  line-height: calc(1vw + 0.6vh + 0.6vmin);
  display: flex;
  justify-content: space-between;
}
.description-tags h1 {
  font-family: "HostRegular",sans-serif;
  color: rgba(255, 255, 255, 0.296);
  float: left;
  width: 40%;
  letter-spacing: 6px;
  line-height: calc(.8vw + .6vh + 0.6vmin);
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
  margin-block-start: 0em;
  margin-block-end: 1em;
  text-transform: uppercase;
}
.description-CS a {
  width: 50%;
  text-transform: uppercase;
  color: #3075ff;
  font-size: calc(0.6vw + 0.6vh + 0.8vmin);
  display: inline-block;
}

.image-div {
  text-align: center;
  justify-content: center;
  line-height: calc(1vw + 0.6vh + 0.6vmin);
}

.image-div p {
  color: #f884c4;
  font-family: "HostLight", sans-serif;
  font-style: italic;
  font-size: calc(1vw + 0.2vh + 0.2vmin);
  margin-block-start: 0.5em;
  margin-block-end: 0em;
}

.description-CS h1 {
  font-family: "HostRegular",sans-serif;
  color: rgba(255, 255, 255, 0.296);
  float: left;
  width: 100%;
  letter-spacing: 6px;
  line-height: calc(1vw + 2vh + 0.6vmin);
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
  margin-block-start: 0em;
  margin-block-end: 1em;
  text-transform: uppercase;
}
.case-study-text {
  font-family: "HostLight", sans-serif;
  color: rgb(255, 255, 255);

  font-weight: 100;
  line-height: calc(.9vw + 0.8vh + 0.8vmin);
  font-size: calc(0.6vw + 0.6vh + 0.6vmin);

  margin-block-start: 0em;
  margin-block-end: 0.2em;
}
.case-study-text span{
  color: rgb(231, 255, 124);
  font-family: "HostRegular", sans-serif;

}


.team{
  text-decoration: none;
  

}

.Text-CS {
  width: 100%;
}
.green {
  font-family: "HostMedium", sans-serif;
  text-transform: uppercase;
  color: #3075ff;
  width: 100%;
}
.case-study-ng {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  
  width: 70%;
}
.case-study-quoation {
  font-family: "EditorialItalic";
  font-size: calc(1.3vw + 1.3vh + 1.3vmin);
  line-height: calc(1.7vw + 1.8vh + 1.5vmin);
  color: #ff3097;
  width: 80%;
  text-transform: none;
  margin-block-start: 0em;
  margin-block-end: 0em;

  
}

.case-study-tags {
  width: 50%;
  position: relative;
}

.case-study-main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}

.case-study {
  position: relative;
  width: 100%;
  border-radius: 10px;
  height: auto;
  padding: 0;
}
.fill {
  width: 100%;
  height: auto;
}
.split {
  width: 47.7%;
}

.key_fact h4{
  font-family: "HostRegular", sans-serif;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.566);
  font-weight: 100;
  margin-block-start: 0em;
  margin-block-end: 0.6em;
}
.key_fact p{
  font-family: "HostRegular", sans-serif;
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: white;
  font-weight: 100;

}


.key_fact {
  padding: 20px 20px 20px 20px;
  background-color: rgba(255, 234, 0, 0.063);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 0, 0.095);
}

.footer-ng {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
.pattern2 {
  display: none;
}

@media screen and (max-width: 1215px) {
  .case-study-parent {
    width: 100%;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
  }
  .case-study-child {
    width: 100%;
    margin-top: 10h;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
  .caseStudy-container {
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 800px) {
  .case-study-parent {
    width: 100%;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
  }
  .case-study-child {
    width: 100%;
    margin-top: 10vh;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
  }
  .caseStudy-container {
  
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }
  .description-CS {
    display: flex;
    flex-wrap: wrap;
  }
  .description-CS h1 {
    line-height: calc(1vw + 2vh + 2.5vmin);
    font-size: calc(1vw + 1vh + 1vmin);
    width: 100%;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
  .description-tags {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }


  .description-tags h1 {
    line-height: calc(1vw + 2vh + 2.5vmin);
    font-size: calc(1vw + 1vh + 1vmin);
    width: 100%;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
  .case-study-ng {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    float: right;
    width: 100%;
  }
  .case-study-text {
    font-family: "Roobert-Regular";
    color: rgb(214, 214, 214);
    width: 100%;
    line-height: calc(1vw + 1.5vh + 2vmin);
    font-size: calc(1vw + 1vh + 1vmin);
    float: right;
    margin-block-start: 0em;
    margin-block-end: 0.5em;
  }
  .green {
    width: 100%;
    color: #3075ff;
    line-height: calc(1.5vw + 1.5vh + 1.5vmin);
    font-size: calc(1.5vw + 1vh + 1vmin);
  }
  .case-study-tags {
    width: 100%;
    position: relative;
  }
  .tag {
    color: rgb(214, 214, 214);
    font-size: calc(1.5vw + 1vh + 1vmin);
    padding: 10px 20px;
  }
  .case-study-main {
    gap: 5px;
  }
  .case-study {
    margin-bottom: 0px;
  }
  .split {
    width: 100%;
  }
  .thank-you-div {
    margin-top: 20px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .thank-you-div h2 {
    font-family: "Roobert-Regular";
    line-height: calc(1vw + 2vh + 2.5vmin);
    font-size: calc(1vw + 2vh + 1vmin);
    width: 100%;
    margin-bottom: 20px;
  }
  .thank-you-div a {
    font-family: "Roobert-Regular";
    font-size: calc(1.5vw + 1vh + 1vmin);
    width: 100%;
  }
  .thank-you-div h1 {
    font-size: calc(6vw + 2.5vh + 0.8vmin);
    width: 100%;
    text-align: center;
  }
  .case-study-heading {
    font-size: calc(2vw + 1vh + 2vmin);
  }

  .case-study-desc {
    font-family: "HostMedium", sans-serif;
    font-size: calc(2vw + 2vh + 2vmin);
    line-height: calc(2vw + 2vh + 2.5vmin);
    color: white;
    width: 100%;
    margin-block-start: 0em;
    margin-block-end: 0.5em;
    display: inline-block;
  }
  .ng-content-cs {
    display: flex;
    flex-direction: column;
  }
  .date-launch {
    font-family: "HostLight";
    line-height: calc(1vw + 2vh + 2.5vmin);
    font-size: calc(1vw + 1.5vh + 1vmin);
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    flex-direction: column;
    margin-top: 20px;
  }


  .date-launch p {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }
  .date-launch a {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }
  .navbar-container-nda {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .backtohome {
    float: right;
    text-decoration: none;
    font-family: "Roobert-Regular";
    font-size: calc(1vw + 1vh + 1vmin);
    color: white;
    text-align: center;
    padding: 15px 30px;
    transition: all 0.2s ease 0s;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50px;
  }

  .nda-div {
    position: absolute;
    width: 95vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    align-items: center;
}


  .text-nda-1 {
    font-size: calc(2vw + 1vh + 1vmin);
    width: 100%;
  }
  .text-nda-2 {
    font-size: calc(1.5vw + 1vh + 1vmin);
    margin-block-start: 0.5em;
    margin-block-end: 1em;
  }


  .circle4 {
    width: 200px;
    height: 200px;
    bottom: 0;
  }
  .nda-2 {
    width: calc(8vw + 8vh + 8vmin);
    margin-left: auto;
    margin-right: auto;
  }
  .heading-nda {
    font-weight: 100 ;
    position: relative;
    font-size: calc(6vw + 3vh + 3vmin);
    color: white;
    font-family: "EditorialLight",serif;
    margin-block-start: 0em;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-block-end: 0.1em;
  }

  h2{
    font-weight: inherit;
  }
  .password-input {
    line-height: calc(1vw + 1vh + 0.8vmin);
    font-size: calc(1vw + 1vh + 1vmin);
    padding: 18px 18px;
    width: 70%;
  }
  .case-study-quoation {
    font-size: calc(2vw + 1.5vh + 1.5vmin);
    line-height: calc(2vw + 2vh + 2vmin);
    width: 100%;
  }
  .image-div p {
    font-size: calc(1vw + 1vh + 1vmin);
    line-height: calc(1.5vw + 1.2vh + 1vmin);
    margin-block-end: 1em;
  }
  .cs-launch {
    display: flex;
    flex-wrap: wrap;
  }

  .cs-launch a {
    color: #8cfff5;
  }
  .backtohome2 {
    float: right;
    font-size: calc(1vw + 1vh + 1vmin);
    padding: 15px 40px;
    left: calc(20vw + 10vh + 5vmin);
    box-shadow: 0 4px 50px rgba(94, 20, 143, 0.555);
  }
  .navbar-container-nda {
    padding-top: 0px;
  }

  .pattern {
    display: none;
  }

  .pattern2 {
  display: none;

  }
  .key-fact {
    width: fit-content;

    padding-left: 20px;

}
.key-fact p {

  font-size: calc(1vw + 1vh + 1vmin);

}
.key-fact span {
  font-size: calc(1vw + 1vh + 1vmin);
 
}
.key-fact h3 {
  font-size: calc(1vw + 1vh + 1vmin);
  line-height:calc(1.5vw + 1.5vh + 1vmin) ;
 
}
.video-desc{
  
  border-end-end-radius: 12px;
  border-bottom-left-radius: 12px;
}

.video-desc{
  display: flex;
  background-color: white;
  padding: 16px;
  flex-direction: column;
  object-fit: cover;
  border-end-end-radius: 12px;
  border-bottom-left-radius: 12px;
}
.key_fact {
  margin-top: 20px;
 margin-bottom: 20px;
}
}
