.react-responsive-modal-root {
  position: fixed;
  top: 0px;
  bottom: 15px;
  left: 0;
  right: 0;
  z-index: 1000;
}

.react-responsive-modal-overlay {
  background: rgba(18, 18, 34, 0.95);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.react-responsive-modal-modal {
  max-width: 97vw !important;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #22233d !important ;
  border-radius: 20px;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0.83rem !important;
  padding: 1rem  !important;
  position: relative;
  overflow-y: auto;
}

.design-process-heading {
  font-size: 105px;
  color: white;
  font-family: "BasementGrotesk";
  -webkit-text-stroke-width: 1px;
  -webkit-text-fill-color: transparent;
  margin-block-start: 0.83em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
}

.process-div1 {
  margin-bottom: 20px;
  height: 90vh;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  
}
.design-process-image{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.case-study-modal {
  width: 95vw;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}
.closeButton {
  position: relative;
  font-family: "Roobert-Regular";
  font-size: calc(0.6vw + 0.4vh + 0.5vmin);
  color: #f5eeff;
  background-color: #222238;
  border: 2px solid rgb(255, 255, 255, 0.1);
  padding: 16px 22px;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  width: calc(2vw + 5vh + 1vmin);;
}
.closeButton :focus {
  outline: none !important;
}

.process-close {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
  gap: 20px;
}

.closeButton:hover {
  color: white;
  background-color: #7140f2;
  cursor: url('../Assets/cursor.svg'), auto;
}
.close {
  position: relative;
  top: -50px;
}
.closeButton2 {
  width: 12px;
  height: 12px;
  
  top: 2px;
  left: 5px;
  cursor: url('../Assets/cursor.svg'), auto;
}

.closeButton2:hover {
  cursor: url('../Assets/cursor.svg'), auto;
}


@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    transform: scale(0.96);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    transform: scale(100%);
    opacity: 1;
  }
  100% {
    transform: scale(0.96);
    opacity: 0;
  }
}

@media screen and (min-width: 998px) {
  .case-study-modal {
    width: 60vw;
  }
}

@media screen and (min-device-width: 312px) and (max-device-width: 812px) {

 
  .react-responsive-modal-modal {
    max-width: 97vw !important;
    margin: 0.4rem !important;
    padding: 0.3rem !important;
    
  }
  .design-process-heading {
    font-size: 60px;
    width: 80%;
  }

  .process-div1 {
    margin-bottom: 10px;
    height: 220px;
    width: 100%;
    border-radius: 16px;
  }

  .closeButton {
    position: relative;
    font-size: 18px;
    width: calc(5vw + 5vh + 1vmin);
  }

  
  .divider {
    height: 1px;
    width: 80vw;
  }
}
