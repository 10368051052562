#section {
  background-image: linear-gradient(360deg, #0057ff24, #0057ff00);
}
.second-sec {
  margin-top: calc(4vw + 4vh + 7vmin);
  padding-left: calc(4vw + 4vh + 3vmin);
  padding-right: calc(4vw + 4vh + 3vmin);
  display: flex;

  gap: calc(1vw + 1vh + 1vmin);
}

.intentions-head {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: calc(1vw + 1vh + 1vmin);
}

.number {
  color: white;
}
.number span {
  font-family: "EditorialItalic", sans-serif;
  font-size: calc(4vw + 3vh + 3vmin);
  color: #6899f9;
}

.intentions-para img {
  height: auto;
  width: 550px;
}
.custom-border {
  padding-left: calc(4vw + 4vh + 3vmin);
  padding-right: calc(4vw + 4vh + 3vmin);
  border: 1px solid transparent; /* Adjust border width */
  background-color: rgba(255, 255, 255, 0.179);
  border-image-slice: 1;
  border-width: 1px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  margin-top: 80px;
}

.intentions-para img {
  display: block;
}

.numbers {
  padding-left: calc(4vw + 4vh + 3vmin);
  padding-right: calc(4vw + 4vh + 3vmin);
  font-family: "HostLight", sans-serif;
  display: flex;
  font-size: calc(0.6vw + 0.6vh + 0.8vmin);
  justify-content: space-between;
  margin-bottom: 120px;
}

.intentions-head h1 {
  font-family: "EditorialLight", sans-serif;
  font-size: calc(3.5vw + 3vh + 3vmin);
  font-weight: 100;
  color: white;
  margin-block-start: 0em;
  margin-block-end: 0em;
  line-height: calc(2.5vw + 3vh + 4vmin);
}

.intentions-head h1 span {
  font-family: "EditorialLight", sans-serif;
  color: #0057ff;
  padding: 0;
  font-size: inherit;
  border: 0;
  background-color: transparent;
}

.intentions-head span {
  font-family: "HostLight", sans-serif;
  width: fit-content;
  background-color: #0055ff11;
  padding: 10px 20px 10px 20px;
  color: white;
  font-size: calc(0.8vw + 0.5vh + 0.5vmin);
  border-radius: 500px;
  border: 2px dashed #6899f9;
}
.intentions-para {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: calc(1vw + 1vh + 1vmin);
}

.intentions-para p {
  display: flex;
  flex-direction: column;
  font-size: calc(0.6vw + 0.6vh + 0.6vmin);
  color: white;
  font-weight: 100;
  line-height: calc(1vw + 1vh + 1vmin);
  font-family: "HostLight", sans-serif;
}

.dumpyard-text {
  font-family: "EditorialItalic", sans-serif;
  color: white;

  font-size: calc(3vw + 3vh + 3vmin);
  margin-block-start: 0.2em;
  margin-block-end: 0em;
  display: block;
  font-weight: 100;
  font-style: normal;
}

.dumpyard a {
  color: white;
  font-size: calc(0.6vw + 0.5vh + 0.5vmin);
  text-decoration: none;
  margin-block-end: 1em;
  font-family: "HostLight", sans-serif;
}

.dumpyard a:hover{
  cursor: url("../Assets/cursor.svg"), auto;
}

.backtohome-dump {
  float: right;
  text-decoration: none;
  font-family: "Roobert-Regular", sans-serif;
  font-size: calc(0.4vw + 0.6vh + 0.6vmin);
  color: white;
  text-align: center;
  padding: 10px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.432);
  transition: all 0.2s ease 0s;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  text-decoration: none;
  color: whitesmoke;
  text-transform: uppercase;
}

.backtohome-dump:hover,
.backtohome-dump:focus {
  border: 1px solid rgba(255, 255, 255, 0);
  box-shadow: 0 1px 30px rgba(113, 64, 242, 0.8);
  cursor: url("../Assets/cursor.svg"), auto;
  transform: translateY(-0.1em);
}
.navbar-container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.dumpyard p {
  margin-left: auto;
  margin-right: auto;

  font-family: "Roobert-Regular";
  font-size: calc(0.8vw + 0.6vh + 0.6vmin);
  color: rgba(255, 255, 255, 0.264);
  margin-block-start: 0em;
  margin-block-end: 1em;
  width: 100%;
}
.dump-image {
  width: 100%;
  height: auto;
  background-color: blueviolet;
  border-radius: 10px;
}
.dump-image-2 {
  width: 100%;
  height: 85vh;
  background-color: blueviolet;
}

.assembly {
  font-family: "DrukPort", sans-serif;
  font-size: calc(5vw + 5vh + 5vmin);
  text-transform: uppercase;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0%);
  left: 50%;
  color: white;
  font-weight: 400;
  font-style: normal;
}
.assembly-2 {
  font-family: "DrukPort", sans-serif;
  font-size: calc(5vw + 5vh + 5vmin);
  text-transform: uppercase;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0%);
  left: 50%;
  color: #fff500;
  width: 100%;
  font-weight: 400;
  font-style: normal;
}
.assembly-3 {
  font-family: "DrukPort", sans-serif;
  font-size: calc(5vw + 5vh + 5vmin);
  text-transform: uppercase;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0%);
  left: 50%;
  color: #00ffb2;
  font-weight: 400;
  font-style: normal;
}

.dump-button {
  margin-top: 20px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.images {
  width: 100%;

  /* Ensures the container is square */
  position: relative; /* Prevents image overflow */
}

.dump-image,
.dump-video {

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image or video fills the square without distortion */
  border-radius: 10px;
}

.dump-date {
  display: flex;
  color: rgba(255, 255, 255, 0.648);
  align-items: center;
  gap: 12px;
  margin-block-end: 0em;
  font-family: "HostLight", sans-serif;
  font-size: calc(0.4vw + 0.4vh + 0.8vmin);
}

.dump-tag {

  width: fit-content;
 
  font-family: "HostRegular", sans-serif;
  font-size: calc(0.4vw + 0.4vh + 0.8vmin);
  padding: 6px 16px 6px 16px;
  border-radius: 20px;
  background-color: #151e3022;
  border: 2px solid #0055ff6b;

}

.dump-pills{
  position: absolute;
  display: flex;
  top: 24px;
  left: 24px;
  gap: 16px;
}

.dump-link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: 24px;
  left: 48px;
  font-family: "HostRegular", sans-serif;
  font-size: calc(.4vw + .4vh + .8vmin);
  padding: 6px 16px;
  border-radius: 20px;
  color: white;
  text-decoration: none;
  background-color: rgba(21, 30, 48, .1843137254901961);
  border: 2px solid rgba(61, 61, 61, .4196078431372549);
  mix-blend-mode: exclusion;
  transition: all .2s ease;
  }
  .dump-link:hover{
    background-color: rgba(255, 255, 255, 0.836);
    color: rgb(23, 23, 23);
    font-family: "HostMedium", sans-serif;
  }

.dump-tag p {
  color: white;
  mix-blend-mode: exclusion;
  margin-block-start: 0em;
  margin-block-end: 0em;
}


.dump-date img {
  width: 28px;
  opacity: 0.2;
  height: auto;
}

.button2 {
  text-decoration: none;
  font-family: "Roobert-Regular";
  font-size: calc(0.4vw + 0.4vh + 0.5vmin);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.432);
  padding: 8px 20px;
  border-radius: 12px;
  transition: all 0.2s ease 0s;
  margin-left: 10px;
  align-items: center;
  text-align: center;
  display: flex;
  gap: 10px;
}
.download {
  width: 25px;
}
.button2:hover,
.button2:focus {
  border: 1px solid rgba(255, 255, 255, 0);
  box-shadow: 0 1px 30px rgba(113, 64, 242, 0.8);
  cursor: url("../Assets/cursor.svg"), auto;
  transform: translateY(-0.1em);
}
.responsive-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Ensures two images per row */
  gap: 20px; /* Adds space between images */
  justify-items: center; /* Center aligns images in each grid cell */
  width: 100%;
  max-width: 1200px; /* Optional: Limits the total width of the grid */
  margin: 0 auto; /* Centers the grid horizontally */
}

.dumpyard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  margin-bottom: calc(10vw + 10vh + 10vmin);
}

.dumpyard {
  margin-top: calc(2vw + 2vh + 2vmin);
  width: 100%;
  max-width: 1200px; /* This ensures it does not stretch beyond the grid */
  text-align: left; /* Aligns the content to the left */
  margin-bottom: 20px;
}

.numbers p {
  margin-block-end: 0em;
  margin-block-start: 0em;
  width: auto;
}

@media screen and (max-device-width: 800px) {
  .dumpyard {
    margin-top: 30px;
  }

  .second-sec {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 0;
  }
  .intentions-head {
    width: 100%;
    flex-direction: column-reverse;
    gap: 40px;
  }
  .intentions-para {
    width: 90%;
  }

  .intentions-para p {
    line-height: calc(1vw + 1.5vh + 2vmin);
    font-size: calc(1vw + 1vh + 0.7vmin);
  }

  .intentions-para img {
    display: none;
  }
  .intentions-head span {
    font-size: calc(0.8vw + 0.8vh + 0.8vmin);
    border: 2px dashed #6899f9;
  }

  .custom-border {
    width: 60%;
  }
  .numbers {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    font-size: calc(1vw + 1vh + 1vmin);
    justify-content: none;
    gap: 20px;
    width: fit-content;
  }

  .numbers p {
    width: 80%;
  }

  .info1,
  .info4 {
    width: 50%;
  }

  .number span {
    font-family: "EditorialItalic", sans-serif;
    font-size: calc(4vw + 4vh + 4vmin);
    color: #6899f9;
  }

  .backtohome-dump {
    float: right;
    text-decoration: none;
    font-family: "Roobert-Regular";
    font-size: calc(1.5vw + 1.2vh + 1vmin);
    color: white;
    text-align: center;
    padding: 10px 25px;
    transition: all 0.2s ease 0s;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50px;
  }

  .dumpyard p {
    line-height: calc(1vw + 2vh + 2vmin);
    font-size: calc(1.5vw + 1vh + 1.55vmin);
    width: 100%;
  }
  .responsive-grid {
    display: flex;
    gap: 10px;

    flex-direction: column;
  }
  .dumpyard a {
 
    font-size: calc(1vw + 1vh + 1vmin);
  
}
.dump-date {
  margin-block-start: .5em;
  margin-block-end: 1.5em;

  font-size: calc(1vw + 1vh + 1vmin);
}

  .navbar-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .dump-image {
    width: 100%;
    height: auto;
    margin-top: 0px;
    border-radius: 5px;
  }
  .dump-image-2 {
    width: 100%;
    height: 40vh;
    margin-top: 0px;
  }
  .images {
    width: 100%;
    height: auto;
  }
  .button2 {
    font-size: calc(1vw + 1vh + 1vmin);

    border: 1px rgba(255, 255, 255, 0.196) solid;

    border-radius: 12px;
    padding: 10px 20px;
    margin-left: 0px;
    margin-right: 10px;
  }
  .dump-button {
    position: relative;
    left: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .dumpyard-container {
 
    width: 90%;
    padding: 20px;

}
.dumpyard-text {
 
  font-size: calc(4vw + 4vh + 4vmin);
  
}
.dump-date img {
  width: 20px;
 
}
.dump-pills {
  
  top: 16px;
  left: 16px;

}
 .dump-tag {
  font-size: calc(.8vw + .8vh + .8vmin);
}
.dump-link{
  font-size: calc(.8vw + .8vh + .8vmin);
}

}
