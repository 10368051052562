.contact-div {
  margin-top: calc(7vw + 8vh + 8vmin);
  text-align: left;
  margin-bottom: calc(3vw + 3vh + 3vmin);
}
.footer-main {
  background-image: linear-gradient(180deg, #0055ff00 0%, #0055ff2a 120%);
  background: url("../Assets/grid_bg.png");
  background-size: cover;
}
.scroll-magic {
  width: 100vw;
  height: auto;
  background-size: cover;
}
.get-in-touch {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.footer-animation {
  height: auto;
  width: 40%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.talk {
  font-family: "HostLight", sans-serif;
  font-size: calc(1vw + 0.3vh + 0.2vmin);
  color: whitesmoke;
  margin-block-end: 1em;
  padding-left: calc(4vw + 4vh + 3vmin);
}

.get-in-touch h2 {
  font-size: calc(6vw + 6vh + 6vmin);
  font-family: "EditorialLight", sans-serif;

  margin-block-start: 0.1em;
  margin-block-end: 0em;
  font-weight: 400;

  padding-left: calc(4vw + 4vh + 3vmin);
  font-style: normal;
}


.get-in-touch a:hover {
  cursor: url("../Assets/open.svg"), auto;
}
.get-in-touch h2 a {
  color: white;
  text-decoration: none;
}

.mask {
  font-family: "HostLight", sans-serif;

  color: white;
  padding-left: calc(4vw + 4vh + 3vmin);
}

.mask h1 {
  font-size: calc(1vw + 1vh + 1vmin);
  font-weight: 100;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.email-call {
  display: flex;
  gap: 20px;
  align-items: center;
}

.button {
  text-align: center;
  padding: 12px 24px 12px 24px;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
  border-radius: 50px;
  
}

.call {
  background-color: #f9e16820;
  border: 1px solid #f9e268;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.copy {
  background-color: #6898f921;
  border: 1px solid #6899f9;
  margin-block-start: 0em;
  margin-block-end: 0em;
 


}

.marquee-contact {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 20s linear infinite;
}

.marquee-contact p {
  display: block;
  margin-block-start: 0.1em;
  margin-block-end: 0.0001em;
}
.marquee-contact p h1 {
  font-family: "HostLight";
  font-size: calc(2vw + 3vh + 2vmin);
  letter-spacing: 0.8px;

  color: #0057ff;
}

.marquee-contact p {
  display: inline-block;
}
.footer {
  padding-left: calc(4vw + 4vh + 3vmin);
  padding-right: calc(4vw + 4vh + 3vmin);
}
.footer-left {
  display: flex;
  font-family: "Roobert-Regular";
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.footer-left p {
  color: rgba(244, 238, 253, 0.484);
}
@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.contact-div ul {
  font-family: "HostLight";
  color: white;
  list-style: none;
  font-size: calc(1vw + 0.5vh + 0.5vmin);
  display: flex;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: calc(4vw + 4vh + 3vmin);
}
.↗ {
  transition: transform ease 300ms;
}

.contact-div ul li a {
  text-decoration: none;
  color: whitesmoke;
}

.contact-div ul li a:hover,
.contact-div ul li a:focus {
  cursor: url("../Assets/cursor.svg"), auto;
}
.↗:hover {
  transform: translate(0, -10px);
}

.copy-to-clipboard {
  width: 25%;
  height: auto;
  position: relative;
  float: right;

  margin-bottom: 20px;
}
.email {
  background-color: #7140f2;
}
.email-img {
  background-color: #121222;
}

@keyframes slideUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .contact-sub {
    width: 90%;
  }

  

  .footer-main {
    width: 100%;
    left: 30px;
  }
  .container-1 {
    width: 36vw;
    height: 120px;
  }

  .text {
    left: 120px;
  }
  .text h3 {
    font-size: 18px;
  }
  .text p {
    font-family: "Roobert-Regular";
    font-size: 16px;
    width: 100%;
  }
  .icon-div {
    border-radius: 12px;
    height: 100px;
    width: 90px;
  }
  .copy-to-clipboard {
    width: 30%;
    right: 10%;
  }
}


@media (max-width: 800px) {
  .contact-div {
    margin-top: calc(1vw + 1vh + 1vmin);
    text-align: left;
    margin-bottom: 20px;
  }
  .talk {
    font-family: "Roobert-Regular";
    font-size: calc(1vw + 1vh + 01vmin);
    color: whitesmoke;
    padding-left: 20px;
  }

  .get-in-touch h2 {
    font-size: calc(8vw + 6vh + 6vmin);
    margin-left: auto;
    margin-right: auto;
  }
  .get-in-touch h2 {
    font-size: calc(10vw + 6vh + 6vmin);
    color: rgb(255, 255, 255);
    text-decoration: none;
    margin-left: auto;
    line-height: calc(5vw + 5vh + 5vmin);
    margin-right: auto;
    position: relative;
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
    font-weight: 400;
    font-style: normal;
  }
  .get-in-touch h2 {
    font-size: calc(3vw + 6vh + 6vmin);
    font-family: "EditorialLight", sans-serif;
    margin-block-start: 0.5em;
    margin-block-end: 0em;
    font-weight: 400;
    padding-left: 20px;
    font-style: normal;
  }
  .contact-sub {
    position: relative;
    font-family: "Roobert-Regular";
    left: 10px;
    color: rgb(187, 184, 214);
    margin-block-start: 0.1em;
    margin-block-end: 1.5em;
    width: 90vw;
    font-size: 16px;
  }

  .copy-to-clipboard {
    width: 70%;
    right: 10%;
  }
  .footer-main {
    display: flex;
    flex-direction: column;
    gap: 0px;
    background: url("../Assets/grid_bg.png");
    background-size: cover;
    background-size: auto;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .footer-animation {
  display: none;
}


  .container-1 {
    min-width: 100%;
    width: 100%;
    height: 110px;
    margin-bottom: 10px;
  }
  .text {
    left: 25px;
  }


 .footer{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
 }
  .footer-left {
    text-align: left;
    justify-content: center;
    width: 100%;
    font-size: calc(1vw + 1vh + 1vmin);
    gap: 20px;
  }

  
  .footer-left p {
    line-height: calc(1vw + 1vh + 3vmin);
    margin-block-start: 0em;
    margin-block-end: 0em;
    width: 100%;
  }
  .mask h1 {
    font-size: calc(1vw + 2vh + 1vmin);
    font-weight: 100;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  .call {
    background-color: #f9e16820;
    border: 1px solid #f9e268;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  .marquee-contact {
    animation: marquee 40s linear infinite;
  }

  .marquee-contact p h1 {
    font-size: calc(1.5vw + 3vh + 2vmin);
  }
  .email-call {
    display: flex;
    flex-wrap: wrap;

    gap: 20px;
    align-items: center;
  }
  .button {
    text-align: center;
    padding: 12px 24px 12px 24px;
    font-size: calc(1vw + 1vh + 1vmin);
    border-radius: 50px;
  }
  .mask {
    width: 80vw;
    padding-left: 20px;
  }
  .contact-div ul {
    font-family: "HostLight";
    text-transform: uppercase;
    color: white;
    list-style: none;
    font-size: calc(1vw + 2vh + 1vmin);
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding-left: 20px;
  }



  .footer-left {
    display: flex;
    font-family: "Roobert-Regular";

    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}
